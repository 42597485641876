import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
const Login = lazy(() => import("../pages/Authentication/Login"));
const Logout = lazy(() => import("../pages/Authentication/Logout"));
const ForgetPwd = lazy(() => import("../pages/Authentication/ForgetPassword"));
const ResetPwd = lazy(() => import("../pages/Authentication/ResetPassword"));
const Home = lazy(() => import("../pages/Authentication/Home"));

// Dashboard
const Dashboard = lazy(() => import("../pages/Dashboard"));

// Client Management //
// Households
const Households = lazy(() => import("../pages/Households"));
const AddHouseholds = lazy(() => import("../pages/Households/AddHousehold"));
const View = lazy(() => import("../pages/Households/View"));

// Cases
const Cases = lazy(() => import("../pages/Cases"));
const AddNewCase = lazy(() => import("../pages/Cases/AddNewCase"));
const EditCase = lazy(() => import("../pages/Cases/EditCase"));

// Client Management //

// Office Management //
// Users
const Users = lazy(() => import("../pages/Users"));
// Groups
const Groups = lazy(() => import("../pages/Groups"));
// Maintenance
const Maintenance = lazy(() => import("../pages/Maintenance"));
// Office Management //

//------------------------------------------ start import frontend layout ---------------------------------------//
// auth
const FrontendLogin = lazy(() => import("../pages/Frontend/Auth/UserLogin"));
const CustomerLogin = lazy(() =>
  import("../pages/Frontend/Auth/CustomerLogin")
);
const CustomerForgot = lazy(() =>
  import("../pages/Frontend/Auth/CustomerForgot")
);
const CustomerReset = lazy(() =>
  import("../pages/Frontend/Auth/CustomerReset")
);
const NewLogin = lazy(() => import("../pages/Frontend/Auth/NewLogin"));
const FrontendLogout = lazy(() =>
  import("../pages/Frontend/Customer/Logout/Logout")
);
const TermsConditions = lazy(() =>
  import("../pages/Frontend/Auth/TermsConditions")
);
const TermsConditionsFood = lazy(() =>
  import("../pages/Frontend/Auth/TermsConditionsFood")
);
const CreateAccount = lazy(() =>
  import("../pages/Frontend/Auth/CreateAccount")
);
const ActivateAccount = lazy(() =>
  import("../pages/Frontend/Auth/ActivateAccount")
);
const ShowMaintenance = lazy(() =>
  import("../pages/Frontend/Auth/ShowMaintenance")
);

// new user
const NewUser = lazy(() => import("../pages/Frontend/NewUser"));
// const Registered = lazy(() => import("../pages/Frontend/NewUser/Registered"));

// customer
const Customer = lazy(() => import("../pages/Frontend/Customer"));
const AddRequest = lazy(() =>
  import("../pages/Frontend/Customer/MyRequests/AddRequest")
);
const EditRequest = lazy(() =>
  import("../pages/Frontend/Customer/MyRequests/EditRequest")
);

const Import = lazy(() => import("../pages/Import"));
const ReportPage = lazy(() => import("../pages/Report/ReportPage"));
const HuttoReport = lazy(() => import("../pages/Report/HuttoReport"));
const SeniorsReport = lazy(() => import("../pages/Report/SeniorReport"));
const DailyReport = lazy(() =>
  import("../pages/Report/DailyReport/DailyReport")
);
const AtomFundReport = lazy(() => import("../pages/Report/FunderReport"));
const AmazonReport = lazy(() => import("../pages/Report/AmazoneReport"));

const userRoutes = [
  { path: "/home", exact: true, component: Home },

  //dashboard
  { path: "/dashboard", exact: true, component: Dashboard },

  //household
  { path: "/households", exact: true, component: Households },
  { path: "/new_households", exact: true, component: Households },
  { path: "/active_households", exact: true, component: Households },
  { path: "/inactive_households", exact: true, component: Households },
  { path: "/intransition_households", exact: true, component: Households },
  { path: "/usdahh_households", exact: true, component: Households },
  { path: "/add-households", exact: true, component: AddHouseholds },
  { path: "/households-view/:id", exact: true, component: View },
  { path: "/deleted_households", exact: true, component: Households },

  //cases
  { path: "/cases", exact: true, component: Cases },
  { path: "/new_cases", exact: true, component: Cases },
  { path: "/processed_cases", exact: true, component: Cases },
  { path: "/approved_cases", exact: true, component: Cases },
  { path: "/completed_cases", exact: true, component: Cases },
  { path: "/denied_cases", exact: true, component: Cases },
  { path: "/expaired_cases", exact: true, component: Cases },
  { path: "/referred_cases", exact: true, component: Cases },
  { path: "/cancelled_case", exact: true, component: Cases },
  { path: "/add-new-case", exact: true, component: AddNewCase },
  { path: "/edit-case", exact: true, component: EditCase },
  { path: "/deleted_cases", exact: true, component: Cases },

  //admin
  { path: "/users", exact: true, component: Users },
  { path: "/groups", exact: true, component: Groups },
  { path: "/maintenance", exact: true, component: Maintenance },
  { path: "/import", exact: true, component: Import },
  { path: "/report", exact: true, component: DailyReport },
  { path: "/household_report", exact: true, component: ReportPage },
  { path: "/case_report", exact: true, component: ReportPage },
  { path: "/family_report", exact: true, component: ReportPage },
  { path: "/hutto_report", exact: true, component: HuttoReport },
  { path: "/seniors_report", exact: true, component: SeniorsReport },
  { path: "/daily_report", exact: true, component: DailyReport },
  { path: "/atom_fund_report", exact: true, component: AtomFundReport },
  { path: "/amazon_report", exact: true, component: AmazonReport },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/home" /> },
];

const authRoutes = [
  { path: "/secure-admn", component: Login },
  { path: "/logout", component: Logout },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password", component: ResetPwd },

  { path: "/login", component: FrontendLogin },
  { path: "/customer-login", component: CustomerLogin },
  { path: "/customer-forgot", component: CustomerForgot },
  { path: "/customer-reset", component: CustomerReset },
  { path: "/new-login", component: NewLogin },
  { path: "/user/login", component: FrontendLogin },
  { path: "/user/terms&conditions", component: TermsConditions },
  { path: "/user/terms&conditions&food", component: TermsConditionsFood },
  { path: "/user/create-account", component: CreateAccount },
  { path: "/user/activate-account", component: ActivateAccount },
  { path: "/user/new", component: NewUser },
  { path: "/maintenanc-mode", component: ShowMaintenance },
  { path: "/user/customer", component: Customer },
  { path: "/user/logout", component: FrontendLogout },
  //cases
  { path: "/add-user-new-case", exact: true, component: AddRequest },
  { path: "/edit-user-case", exact: true, component: EditRequest },
];

export { userRoutes, authRoutes };
